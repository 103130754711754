<template>
  <b-card class="login-body mb-5">
    <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.LOGIN_BANKID')}}</h3>
    <form>
      
      <div id="qr-container">
        <img style="margin:auto; width: 60%;" align-h="center" :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" class="img-fluid mb-8" alt="bankid" />

        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>

      </div>
     
      <div v-if="show_no_permissions" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
      </div>

      <div v-if="!bankid_order_ref" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text"><p>{{$t('ACCOUNT.NO_BANKID_DATA')}}</p></div>
      </div>
      
      
    </form>
  </b-card>
</template>

<style lang="scss" scoped>

#qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_USER_ID, SET_COMPANY_ID } from '@/core/services/store/common.module';
import { SET_IS_SUPERADMIN, SET_IS_THS, LOGOUT } from '@/core/services/store/auth.module';

import axios from 'axios';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import store from '@/core/services/store';
import { hasAccessToPageForCurrentCompany, initFromLogin, getAnyPageWithAccess, clearCurrentCompanyId } from '@/core/services/companyAccess';
import { is_mobile } from '@/core/services/utils';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { requiredUnless } from 'vuelidate/lib/validators';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  mixins: [validationMixin],
  name: 'login-bankid-return',
  data() {
    return {
      debug: false,
      show_no_permissions: false,
      bankid_order_ref: null,
    };
  },

  validations() {
    return {
      form: {
        personnr: {
          required: true,
          validate_personnr: validate_personnr,
          isPersonnrValid: isPersonnrValid
        }
      }
    };
  },
  
  async mounted() {

    const order_ref = this.$route.query.order_ref;

    this.debug = getKeyValue('debug');

    if (this.debug) {
      console.log('--- BANKID-RETURN DEBUG ---');
      console.log('order_ref', order_ref);
    }

    if (!order_ref || (order_ref+'').length < 10) {
      const stored_order_ref = getKeyValue('bankid_order_ref');
      console.log('using stored order ref', stored_order_ref);
      this.bankid_order_ref = stored_order_ref;
      this.simple_bankid_collect(stored_order_ref);
    }
    else {
      console.log('using query order ref', order_ref);
      this.bankid_order_ref = order_ref;
      this.simple_bankid_collect(order_ref);
    }
  },

  methods: {
    async simple_bankid_collect(order_ref) {
      try {
        const res = await axios.get(`/bankid/simple/collectstatus?intent=login&order_ref=${order_ref}`);

        if (this.debug) {
          console.log('simple_bankid_collect', res.status, res.data);
          return;
        }

        if (res.status === 200) {

          if (res.data.status === 'authenticated') {
            console.log('success authentication');

            // success
            if (res.data.selected_company_id) {
              this.$store.dispatch(SET_COMPANY_ID, res.data.selected_company_id);
            }

            this.$store.dispatch(LOGIN, {
              user: {
                user_id: res.data.id,
                email: res.data.name,
              },
              token: res.data.token,
              refresh_token: res.data.refresh_token.refresh_token,
              expires_ts: res.data.refresh_token.expires_ts
            });
            
            this.$router.push({ name: 'ml-auth-forward' });
          }
          else if (res.data.status === 'no_access') {
            console.log('no_access', res.data);

            this.show_no_permissions = true;

            this.$router.push({ name: 'ml-login'});
          }
          else {
            console.log('not authenticated', res.data);

            this.$router.push({ name: 'ml-login'});
          }
        }
        else if (res.status === 404) {
          /// when the user wasnt found
          this.$router.push({ name: 'ml-login'});
        }
      }
      catch (err) {
        console.error('simple collect exception', err);
      }
    },

  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
